import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const DoDontComponent = makeShortcode("DoDontComponent");
const Do = makeShortcode("Do");
const ImageCaption = makeShortcode("ImageCaption");
const Dont = makeShortcode("Dont");
const AdaptiveImage = makeShortcode("AdaptiveImage");
const CaptionTitle = makeShortcode("CaptionTitle");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton link="/content" mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Confirmations</Heading>
      <Text mdxType="Text">
  By confirming intent, we can prevent the loss of important work and data, or reduce the risk of unintended sharing—empowering people to use our apps confidently and safely.
      </Text>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">When to use</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use confirmations when the action can significantly impact projects, teams, or data.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use confirmations when it's difficult or impossible to reverse an action.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use confirmations for easy-to-undo actions or if they have no implications.<br></br>
    They might become easy to miss if used too often or for actions with minimal impact.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Design your message</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Aim to help people recognize the action just by scanning the heading.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Directly name the action to reflect what's happening (<i>Delete project</i>, <i>Archive team</i>, etc.).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Start with the verb to make the message clear at a glance. <br></br>
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Be direct, concise, and precise.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Skip vague and wordy “Do you want to” or ambiguous “Are you sure.”
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <ImageCaption mdxType="ImageCaption">
    Delete this project?<br></br>
    Continue without saving?<br></br>
    Share reports outside the team?<br></br>
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <ImageCaption mdxType="ImageCaption">
    Are you sure you want to delete this project?<br></br>
    Do you want to continue without saving?<br></br>
    Confirmation required
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Include details</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use the description to give valuable details, such as who or what will be affected.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Let people know if they can't reverse the action.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't repeat the heading to make those details noticeable.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Match the button</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When designing your call to action, ensure it echoes the heading.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Aim to pair the verb with the action to boost confidence in the decision. 
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid vague labels like <i>Confirm</i> or <i>Yes</i>/<i>No</i> to keep the action crystal clear.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Be mindful of widely-used general buttons, such as <i>Cancel</i> or <i>Save</i>.<br></br>
    They might be confusing in certain contexts or when paired with a similar action.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/424e4d73-e274-419e-bf12-7e7e8837a238" darkSrc="https://github.com/user-attachments/assets/e16809f2-24f8-492b-ac63-8501eee3c5a1" alt="An example of clearly structured confirmation messages." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Clear, precise messages ensure smooth navigation and prevent errors.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/456145f7-1292-4839-b1d3-145744abfb9b" darkSrc="https://github.com/user-attachments/assets/24ebcc01-37f1-4665-b032-a8c80515fc23" alt="An example of vague and confusing confirmation messages." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Vague wording adds to cognitive load, lowering trust in the interface.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <Text mdxType="Text">Share your feedback on the #design-systems channel in Slack.</Text>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      